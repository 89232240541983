// import React from "react";
import {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "app/components/shared/enums/global-enums.tsx";
import {AppRoutes} from "app/helpers/routes";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { buttonProps } from "app/components/shared/AppButton/AppButton";

export const navList: buttonProps[] = [
  {
    buttonLabel: "Contact",
    buttonType: ButtonTypeEnum.TEXT,
    buttonSize: ButtonSizeEnum.MEDIUM,

  },
  {
    buttonLabel: "About Us",
    buttonType: ButtonTypeEnum.TEXT,
    buttonSize: ButtonSizeEnum.MEDIUM,
    redirectUrl: AppRoutes.aboutUsPath,
  },
  {
    buttonLabel: "Blog",
    buttonType: ButtonTypeEnum.TEXT,
    buttonSize: ButtonSizeEnum.MEDIUM,

  },
  {
    buttonLabel: "Ask for a demo",
    buttonType: ButtonTypeEnum.TEXT,
    buttonSize: ButtonSizeEnum.MEDIUM,
    userNotLogged: true
  },
  {
    buttonLabel: "Get Started",
    buttonType: ButtonTypeEnum.TEXT,
    buttonSize: ButtonSizeEnum.MEDIUM,
    redirectUrl: AppRoutes.signUpPath,
    userNotLogged: true
  }
];

import React from "react";
import { Card, Typography } from "@mui/material";
import envelopeIcon from "../../../assets/images/EnvelopeSimple.svg";
import "./Contact.scss";
import "../../../../../src/App.scss";

export default function Contact() {
  return (
    <Card className="get-in-touch-container">
      <Typography variant="h4" className={"card-title"}>
        Let's get in touch!
      </Typography>
      <Typography variant="body1" className="subtitle">
        Got questions about Competency Edge? Our team is here to help. Contact us for a
        quick and friendly support.
      </Typography>
      <Typography className="d-f ai-c">
        <img src={envelopeIcon} alt="email icon" />
        <a className="ml-1 c-b link" href="mailto:contact@competency-edge.com">
          Contact@competency-edge.com
        </a>
      </Typography>
    </Card>
  );
}

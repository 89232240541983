import React from "react";
import AppButton from "app/components/shared/AppButton/AppButton.tsx";
// import groupPhoto from "../../../assets/images/group-photo.svg";
import "./Hero.scss";
import { ButtonTypeEnum } from "app/components/shared/enums/global-enums.tsx";
import { RocketLaunch } from "@mui/icons-material";
import { Grid, Container } from "@mui/material";
import competencyEdge from "app/assets/media/competencyEdge.mp4";

export default function Hero() {
  return (
    <Container>
    <Grid  container className=" hero-showcase" spacing={2}>
      <Grid item xs={12} md={6} className="hero-left-content">
        <div className="heading">
          Nurturing <span className="design">Design</span> Competencies
        </div>
        <p>
          Building competent individuals with courses, curriculums,
          collaboration and a whole lot of convenience!
        </p>
        <div className=" hero-cta-container">
          <AppButton
            sx={{marginBottom: '10px !important'}}
            onClickFunc={() => {}}
            buttonLabel={"Get Started"}
            buttonType={ButtonTypeEnum.PRIMARY}
            buttonStartIcon={<RocketLaunch />}
          />
          <AppButton
            sx={{marginBottom: '10px !important'}}
            onClickFunc={() => {}}
            buttonLabel={"How it works"}
            buttonType={ButtonTypeEnum.TEXT_OUTLINE}
          />
        </div>
        <div className="v-space-10"></div>
      </Grid>
      <Grid  item xs={12} md={6} className="d-f ai-c">
        <video src={competencyEdge} controls class="video-bg" />
      </Grid>
    </Grid>
    </Container>
  );
}
